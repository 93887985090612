import AjaxHandler from 'AjaxHandler';
import HereMaps from '../../../../../../Project/Common/code/src/scripts/maps/HereMaps.js';

/**
 * Dealer country select functionality
 *
 * @memberOf module:project/Common
 * @requires module:project/Maps.HereMaps
 * @requires module:project/Common.AjaxHandler
 * @version 1.0.0
 */

class DealerCountrySelect {
    constructor($element) {
        this.$element = $($element);
        this.$dealerData = null;
        this.$resultsFound = null;
        this.$countryInput = $(this.$element).find('.js-dealer-country');
        this.$defaultCountry = $(this.$element).find('.js-default-countrycode');
        this.$countryValue = '';
        this.$rangeValue = '';
        this.$dealerInput = $(this.$element).find('.js-dealer-dealer');
        if ($(this.$element).find('.js-dealer-postal-code').length == 0) {
            this.$dealerPostalCodeInput = $(this.$element).parent().find('.js-dealer-postal-code');
        } else {
            this.$dealerPostalCodeInput = $(this.$element).find('.js-dealer-postal-code');
        }
        //looking for the address details postal code input field outside of (OTC) Dealer Form component
        this.$addressPostalCodeInput = $('.js-address-details-postal-code');
        this.dealerTypes = $(this.$element).find('[data-dealer-types]').attr('data-dealer-types');
        this.dealerRange = $(this.$element).attr('data-dealer-range');
        this.dealerResults = false;
        this.$errorMsgContainer = this.$element.find('.errormessage');
        let dealerRangeSet = false;

        this.latitude = null;
        this.longitude = null;

        //defaults
        this.config = {
            getDealerApiLink: 'api/sitecore/dealerformapi/getdealers',
        };

        if (this.dealerRange !== 'undefined') {
            dealerRangeSet = true;
        }

        // data to send with api calls
        this.data = {
            dealerTypes: this.dealerTypes,
            range: dealerRangeSet ? this.dealerRange : null,
        };

        //init ajax helper
        this.ajax = new AjaxHandler({
            type: 'POST',
        });

        // get zipcode input value
        this.$dealerPostalCodeInput.on({
            'blur.dealercountryselect': this.onPostalCodeInput.bind(this),
        });

        if (this.$addressPostalCodeInput.length > 0) {
            this.$addressPostalCodeInput.blur(() => {
                this.$dealerPostalCodeInput.focus();
                this.$dealerPostalCodeInput.blur();
            });
        }

        this.map = new HereMaps(this.$element, {
            map: {
                center: { lat: 51.43328, lng: 5.50428 },
                zoom: 8,
                events: {
                    onReady: () => {
                        this.onReady();
                    },
                },
            },
        });
    }

    /**
     * Init
     */
    init() {
        $(this.$countryInput).change(() => {
            this.$countryValue = { countryCode: this.$countryInput.val() };
            this.data = $.extend(true, this.data, this.$countryValue);

            if (this.$dealerPostalCodeInput.val() !== '') {
                this.getLocation(this.$countryInput.val(), this.$dealerPostalCodeInput.val());
            } else {
                this.getDealerData(this.data)
                    .then((response) => {
                        this.setDealers(response);
                        this.$dealerData = response;
                        this.dealerResults = true;
                    })
                    .catch((error) => {
                        this.$errorMsgContainer.html(error);
                    });
            }
        });
    }

    /**
     * fire on input of postalcode
     */
    onPostalCodeInput() {
        // when default countrycode is set in backoffice
        if (this.$defaultCountry.length) {
            if (this.$defaultCountry.val().length && this.$dealerPostalCodeInput.val().length) {
                this.$countryValue = { countryCode: this.$defaultCountry.val() };
                this.data = $.extend(true, this.data, this.$countryValue);

                this.getLocation(this.$defaultCountry.val(), this.$dealerPostalCodeInput.val());
            }
        }
        // When country can be selected with selectbox
        else if (this.$countryInput.length) {
            if (this.$countryInput.val().length && this.$dealerPostalCodeInput.val().length) {
                this.getLocation(this.$countryInput.val(), this.$dealerPostalCodeInput.val());
            }
        }
    }

    /**
     * get lat lng from zipcode
     * @param {String} zipcode input value
     */
    getLocation(countrycode, zipcode) {
        this.map
            .getGeoLocation(countrycode, null, zipcode)
            .then((response) => {
                this.latitude = { latitude: response.lat };
                this.longitude = { longitude: response.lng };

                this.data = Object.assign({}, this.data, this.latitude, this.longitude);
                this.getDealerData(this.data)
                    .then((response) => {
                        this.setDealers(response);
                    })
                    .catch((error) => {
                        this.$errorMsgContainer.html(error);
                    });
            })
            .catch((error) => {
                this.$errorMsgContainer.html(error);
            });
    }

    /**
     * Get data according to country or zipcode
     * @param {Object} data obj with values
     */
    getDealerData(data) {
        return this.ajax.request({
            url: '/' + this.config.getDealerApiLink,
            data: JSON.stringify(data),
        });
    }

    /**
     * Set correct dealer based on response
     * @param {Object} dealers response from ajax call in getDealerData
     */
    setDealers(dealers) {
        let newDealerObj = dealers;
        let oldDealerObj = this.$dealerData;

        if (this.dealerResults === true) {
            // object to arrays for easier filtering
            const objValuesA = Object.values(newDealerObj);
            const objValuesB = Object.values(oldDealerObj);

            if (newDealerObj.length !== 0) {
                // populate selectbox with dealers
                this.populateDealerSelect(newDealerObj);

                let filteredKeywords = objValuesA.filter((dealer) => !objValuesB.includes(dealer));
                this.$dealerInput.find('option').attr('selected', false);
                this.$dealerInput
                    .find('option[value="' + filteredKeywords[0].Value + '"]')
                    .attr('selected', 'selected');
            }

            // if result returns empty remove all options but first
            if (newDealerObj.length === 0) {
                this.dealerResults = false;
                // populate selectbox with dealers
                this.populateDealerSelect(oldDealerObj);
            }
        }

        if (this.dealerResults === false) {
            if (dealers) {
                // populate selectbox with dealers
                this.populateDealerSelect(dealers);
            }
        }
    }

    /**
     * Populate form field with country specific dealers
     * @param {Object} obj response from ajax call in getDealerData
     */
    populateDealerSelect(obj) {
        // Remove all options but the first
        this.$dealerInput.find('option').not(':first').remove();

        for (let i = 0; i < obj.length; i++) {
            this.$dealerInput.append($('<option>', { value: obj[i].Value }).text(obj[i].Text));
        }

        this.$dealerInput.find('option:nth-child(2)').attr('selected', true);
    }
}

export default DealerCountrySelect;
