import 'bootstrap/js/dist/collapse.js';
import EqualHeights from 'EqualHeights';

/**
 * Unfolding Grid functionality.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class UnfoldingGrid {
    constructor(element) {
        this.$element = $(element);
        this.$label = this.$element.find('.unfolding-grid__label');
        this.$trigger = this.$element.find('.btn--unfold');
        this.collapsible = this.$element.find('.collapse');
    }

    init() {
        // init collapse (open in edit mode, hidden in other modes)
        if (!scEditMode) {
            this.collapsible.collapse('hide');
        } else {
            this.collapsible.collapse('show');
            console.warn('Sitecore Edit, Preview or Debug modus: Prevented Navigation Scroll functionality.');
        }
        //this.collapsible.collapse('hide');
        this.collapsible.on('show.bs.collapse', this.onShow.bind(this));
        this.collapsible.on('hidden.bs.collapse', this.onCollapsed.bind(this));
        this.collapsible.on('shown.bs.collapse', this.onShown.bind(this));

        // trigger event
        this.$trigger.on('click', this.toggleCollapse.bind(this));
    }

    onShow() {
        this.$trigger.addClass('active');
        this.$label.addClass('hidden');
    }

    onShown() {
        EqualHeights.matchHeight();
    }

    onCollapsed() {
        this.$trigger.removeClass('active');
        this.$label.removeClass('hidden');
    }

    toggleCollapse(e) {
        e.preventDefault();

        if (!this.$element.hasClass('open')) {
            this.$element.addClass('open');
            this.collapsible.collapse('show');
        } else {
            this.$element.removeClass('open');
            this.collapsible.collapse('hide');
        }
    }
}

export default UnfoldingGrid;
