/**
 * Loads an image defined in the srcset of an image and places it as background of the parent container.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class ResponsiveBackground {

    /**
     * constructor
     * @param {jQueryElement} $element jQueryDomElement which is upgraded with this functionality.
     */
    constructor($element) {
        this.$element = $($element);
        this.$img = null;
        this.img = '';
        this.imgSrc = '';
    }

    /**
     * Initialize.
     */
    init() {

        this.$img = this.$element.find('img.bg');

        if (this.$img.length > 0) {
            this.$element.css('background-image', '');

            this.$img.hide();
            this.img = this.$img[0];

            this.$img.off('load.responsivebackground').on('load.responsivebackground', () => {
                this.update();
            });

            if (this.img.complete) {
                this.update();
            }
        }
    }

    /**
     * Updates img tag with source and adds img url to inline background-image css.
     */
    update() {
        let src = typeof this.img.currentSrc !== 'undefined' ? this.img.currentSrc : this.img.src;
        if (this.imgSrc !== src && this.imgSrc.substr(0, 2) !== '%%') {
            this.imgSrc = src;
            this.$element.css('background-image', 'url("' + this.imgSrc + '")');
        }
    }

}

export default ResponsiveBackground;