import Masonry from 'masonry-layout';

/**
 * Masonry grid layout library.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class MasonryBase {

    /**
     * constructor
     * @param {jQueryElement} $element jQueryDomElement which is upgraded with this functionality.
     */
    constructor($element) {
        this.$element = $element;
        this.msnry = null;

        this.options = {
            columnWidth: '.grid-sizer',
            itemSelector: '.itemlist__item',
            originTop: true,
            percentPosition: true,
            horizontalOrder: true
        };
    }

    /**
     * Initialize.
     */
    init(options={}) {
        //this.$masonryList = this.$element.find('.itemlist--masonry');
        this.options = $.extend(this.options, options);
        if ($('.itemlist--masonry').length > 0) {
            this.msnry = new Masonry('.itemlist--masonry', this.options);
        }
    }

    addItems(items) {
        this.msnry.addItems(items);
    }

    remove() {
        this.msnry.remove();
    }

    destroy() {
        this.msnry.destroy();
    }

    reload() {
        this.msnry.reloadItems();
        this.msnry.layout();
    }
}

export default MasonryBase;