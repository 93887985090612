import lodash from 'lodash';
import EventEmitter from './EventEmitter.js'; // this previously was absolute path 'EventEmitter', but we need relative path to be able to import this ValTagManager (in DafTagManager) script in mock-files on DAF-Azure

/** IE9+ '.closest' polyfill */
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}
/** end of polyfill */

/**
 * Manages events and components used by Digital Marketeers.
 *
 * @memberOf module:project/Common
 * @requires lodash
 * @requires module:project/Common.EventEmitter
 * @version 2.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class ValTagManager extends EventEmitter {
    /**
     * Collects all components and elements to send to VTM Helper and adds
     * corresponding event listeners to the collected nodes.
     * @param {Object} [config] Optional config to extend and override default config.
     * @param {String} [config.componentSelector='[data-dm^="component"]'] Selector of components to track events of.
     * @param {String} [config.elementSelectors='a, button, input, select, textarea'] Selectors of elements to track events of.
     * @param {String} [config.fileExtensions='pdf|zip|7zip|doc|docx|xls|xlsx'] Extensions used to determine download link type.
     */
    constructor(config = {}) {
        super();

        this.components = [];
        this.elements = [];
        this.eventLog = [];
        this.viewed = [];
        this.startTimeStamp = Date.now();
        this.lastEventTimeStamp = this.startTimeStamp;

        // default config
        const defaults = {
            obfuscateUserInput: true,
            componentSelector: '[data-dm^="component"]',
            elementSelectors: 'a, button, input, select, textarea',
            fileExtensions: 'pdf|zip|7zip|doc|docx|xls|xlsx',
        };

        // extend/override default config
        this.config = {
            ...defaults,
            ...config,
        };

        // bind event to be able to add and remove binding
        this.eventHandler = this.handleEvent.bind(this);

        // update elements
        this.update(true);

        // object to store current event
        this.currentEvent = {};

        // vtm.view

        // vtm.input.blur

        // vtm.input.change
        // inputs events (obfuscate input flag (gdpr))
        // input type = SELECT, RADIO, CHECKBOX values!

        //this.emit('updated', response, this.data);
    }

    /**
     * Finds DM elements on component level and adds event handlers.
     * @returns void
     */
    init() {
        // bind events
        this.elements.forEach((node) => {
            node.addEventListener('click', this.eventHandler, false);
            node.addEventListener('change', this.eventHandler, false);
            // node.addEventListener('blur', this.eventHandler, false);
        });

        this.components.forEach((elm) => {
            this.trackComponentIfInViewport(elm);
        });

        // Check on scroll if component is in page viewport
        window.addEventListener(
            'scroll',
            lodash.debounce(() => {
                this.components.forEach((node, index) => {
                    this.trackComponentIfInViewport(node, index);
                });
            }, 300)
        );
    }

    /**
     * Creates default DM event to track.
     * @param {Event} e Original Event.
     * @returns {Object} Default DM event data.
     */
    createDMEvent(e) {
        // log current timestamp
        const currentTimeStamp = Date.now();

        // create default data
        const dmEvent = {
            eventName: null,
            eventAction: null,
            elementId: e.currentTarget.id || '',
            elementIndex: parseInt(e.currentTarget.getAttribute('data-dm-element-index')) || '',
            elementName: e.currentTarget.name || null,
            elementValue: e.currentTarget.value || null,
            elementHref: e.currentTarget.href || null,
            elementProtocol: e.currentTarget.protocol || null,
            elementChecked: e.currentTarget.checked || null,
            elementNodeName: e.currentTarget.nodeName,
            elementClassName: e.currentTarget.getAttribute('class') || null,
            elementDataset: e.currentTarget.dataset || null,
            component: this.getElementComponent(e.currentTarget).name,
            componentIndex: parseInt(this.getElementComponent(e.currentTarget).index) || '',
            componentTotal: this.components.length,
            pagePath: window.location.pathname || null,
            originalEvent: e,
            timestamp: currentTimeStamp,
            elapsedTime: currentTimeStamp - this.startTimeStamp,
            elapsedTimeSinceLastEvent: currentTimeStamp - this.lastEventTimeStamp,
        };

        return dmEvent;
    }

    /**
     * Handles events and creates event data to track.
     * After creation, logs to the event log and notifies listeners.
     * @param {Event} e Original triggered event.
     * @param {Boolean} [doLogAndEmit=true] Optional property. If set, event won't be logged and emitted. Necessary if event dat needs to be extended.
     * @returns void
     */
    handleEvent(e, doLogAndEmit = true) {
        const node = e.currentTarget;
        let defEvent = this.createDMEvent(e);

        if (e.type === 'click') {
            // check if click was on button, input[type="submit"] or a
            if (
                node.nodeName === 'BUTTON' ||
                (node.nodeName === 'INPUT' && node.getAttribute('type') === 'submit') ||
                node.nodeName === 'A'
            ) {
                this.currentEvent = {
                    ...defEvent,
                    eventName: 'vtm.click',
                    ...this.getClickAction(e.currentTarget),
                    elementText: this.getElementText(e.currentTarget),
                };

                // categorize
                // Note: it's not clear why this swich state is here, and not in DafTagManager.js. I don't think this project-specific code should be in a generic script
                let cat = null;
                switch (this.currentEvent.eventAction) {
                    case 'button-telephone':
                    case 'link-telephone':
                    case 'button-mailto':
                    case 'link-mailto': {
                        cat = 'contact';
                        break;
                    }
                    case 'button-external':
                    case 'link-external': {
                        cat = 'external-links';
                        break;
                    }
                    case 'button-internal-download':
                    case 'button-external-download':
                    case 'link-internal-download':
                    case 'link-external-download': {
                        cat = 'downloads';
                        break;
                    }
                    case 'link-share':
                    case 'button-share': {
                        cat = 'share';
                        break;
                    }
                    default: {
                        cat = null;
                        break;
                    }
                }

                let newEvent = false;
                if (cat) {
                    newEvent = {
                        ...this.currentEvent,
                        eventName: 'vtm.' + cat,
                    };
                }

                if (doLogAndEmit) {
                    this.logAndEmit(this.currentEvent);
                    if (newEvent) {
                        this.logAndEmit(newEvent);
                    }
                }
            }
        }

        if (e.type === 'change') {
            if (
                (node.nodeName === 'INPUT' && node.getAttribute('type') !== 'submit') ||
                node.nodeName === 'SELECT' ||
                node.nodeName === 'TEXTAREA'
            ) {
                this.currentEvent = {
                    ...defEvent,
                    eventName: 'vtm.input.change',
                    ...this.getChangeAction(e.currentTarget),
                };

                // obfuscate user input because of GDPR
                if (
                    this.config.obfuscateUserInput &&
                    ((node.nodeName === 'INPUT' &&
                        node.getAttribute('type') !== 'radio' &&
                        node.getAttribute('type') !== 'checkbox') ||
                        node.nodeName === 'TEXTAREA')
                ) {
                    this.currentEvent.elementValue = '<<obfuscated>>';
                }

                if (doLogAndEmit) {
                    this.logAndEmit(this.currentEvent);
                }
            }
        }

        // if (e.type === 'blur') {
        //     if ((node.nodeName === 'INPUT' && node.getAttribute('type') !== 'submit') || node.nodeName === 'SELECT' || node.nodeName === 'TEXTAREA') {
        //         this.currentEvent = {
        //             ...defEvent,
        //             eventName: 'vtm.input.blur',
        //         };
        //         if (doLogAndEmit) {
        //             this.logAndEmit(this.currentEvent);
        //         }
        //     }
        // }

        if (e.type === 'view') {
            this.currentEvent = {
                ...defEvent,
                eventName: 'vtm.view',
            };
            // if (e.index) {
            //     this.currentEvent.component += `-${e.index}`;
            // }
            if (doLogAndEmit) {
                this.logAndEmit(this.currentEvent);
            }
        }
    }

    /**
     * Gathers click event data and formats data ready for DM events.
     * @param {Element} node Current HTML node.
     * @returns {Object} Object containing action, text, value, href and protocol.
     */
    getClickAction(node) {
        let dmEvent = {};
        dmEvent.eventAction = null;
        dmEvent.elementValue = null;
        dmEvent.elementHref = null;
        dmEvent.elementProtocol = null;

        if (
            node.nodeName === 'BUTTON' ||
            node.nodeName === 'INPUT' ||
            (node.nodeName === 'A' &&
                (node.classList.contains('btn') || node.classList.contains('button')) &&
                !node.classList.contains('btn-link'))
        ) {
            dmEvent.eventAction = 'button';
        } else {
            dmEvent.eventAction = 'link';
        }

        if (node.href) {
            dmEvent.elementHref = node.href;
            dmEvent.elementProtocol = node.protocol;

            // tel:/mailto:
            if (node.protocol === 'tel:') {
                dmEvent.eventAction += '-telephone';
                dmEvent.elementValue = node.href.replace(/tel:/i, '');
            } else if (node.protocol === 'mailto:') {
                dmEvent.eventAction += '-mailto';
                dmEvent.elementValue = node.href.replace(/mailto:/i, '');
            } else {
                // internal/external?
                if (node.hostname === location.hostname) {
                    dmEvent.eventAction += '-internal';
                } else {
                    dmEvent.eventAction += '-external';
                }
                dmEvent.elementValue = node.href;

                // downloads
                const splitPath = node.href.split('.');
                if (splitPath.length > 1) {
                    const extension = splitPath.pop().toLowerCase();
                    const pattern = `(${this.config.fileExtensions})$`;
                    const regex = new RegExp(pattern, 'i');
                    if (extension.match(regex) !== null) {
                        dmEvent.eventAction += '-download';

                        // filename
                        const splitFilename = node.href.split('/');
                        if (splitFilename.length > 1) {
                            const filename = splitFilename.pop();
                            dmEvent.elementValue = filename;
                        }
                    }
                }
            }
        } else {
            // no href, so this is not a normal link
            dmEvent.eventAction += '-toggle';
        }

        // overrides
        const action = node.getAttribute('data-dm-event-action');
        if (action) {
            dmEvent.eventAction = action;
        }

        const value = node.getAttribute('data-dm-element-value');
        if (value) {
            dmEvent.elementValue = value;
        }

        return dmEvent;
    }

    /**
     * Creates action properties for change events.
     * @param {Element} node
     * @returns {Object} Object containing eventAction, elementValue and elementText.
     */
    getChangeAction(node) {
        let dmEvent = {};
        dmEvent.eventAction = null;
        dmEvent.elementValue = null;
        dmEvent.elementText = null;

        // part of form?
        if (node.closest('form')) {
            dmEvent.eventAction = 'form-';
        } else {
            dmEvent.eventAction = '';
        }

        // node name/type/text
        const nodeName = node.nodeName.toLowerCase();
        if (
            node.nodeName === 'INPUT' &&
            node.getAttribute('type') !== 'checkbox' &&
            node.getAttribute('type') !== 'radio'
        ) {
            dmEvent.eventAction += `${nodeName}-${node.type}-${node.name}`;
        } else if (
            node.nodeName === 'INPUT' &&
            (node.getAttribute('type') === 'checkbox' || node.getAttribute('type') === 'radio')
        ) {
            dmEvent.eventAction += `${node.type}-${node.name}`;
        } else {
            dmEvent.eventAction += `${nodeName}-${node.name}`;
        }

        // label text
        if (node.closest('div').querySelector('label')) {
            dmEvent.elementText = node.closest('div').querySelector('label').innerText;
        }

        if (node.nodeName === 'SELECT') {
            dmEvent.elementText = node.selectedOptions[0].innerText;
        }

        dmEvent.elementValue = node.value;

        return dmEvent;
    }

    /**
     * Finds parent component of Element (if there is one).
     * @param {Element} node
     * @returns {String|null} Name of component, if found.
     */
    getElementComponent(node) {
        let ret = {
            name: 'page',
            index: null,
        };
        let component = node.closest('[data-dm^="component"]');
        if (component) {
            ret = {
                name: component.getAttribute('data-dm').split('.').pop(),
                index: component.getAttribute('data-dm-component-index'),
            };
        }
        return ret;
    }

    /**
     * Gets link or button text if there is a text, or returns img src
     * or icon title if there is an image or icon within the link or button.
     * @param {Element} node Current HTML node.
     * @returns {String} Text of link/button, img src or icon title.
     */
    getElementText(node) {
        let elementText = null;
        if (node.outerText) {
            elementText = node.outerText;
        } else if (node.children.length > 0) {
            if (node.children[0].nodeName === 'IMG') {
                const src = node.children[0].getAttribute('src');
                elementText = `image (${src})`;
            } else if (node.children[0].classList.contains('icon')) {
                elementText = node.children[0].children[0].getAttribute('title');
            }
        }
        return elementText;
    }

    /**
     * If component is in viewport, add it to viewed list (if it isn't already)
     * and create event data.
     * @param {Element} elm Element to check.
     */
    trackComponentIfInViewport(elm) {
        if (ValTagManager.isElementInViewport(elm, 0.75)) {
            const index = elm.getAttribute('data-dm-component-index');
            const name = `${elm.getAttribute('data-dm')} (index: ${index})`;
            if (!this.viewed.includes(name)) {
                this.viewed.push(name);
                this.handleEvent({ type: 'view', currentTarget: elm, target: elm, index: index });
            }
        }
    }

    /**
     * Checks for element in viewport.
     * @param {*} el
     * @param {*} offsetTop
     */
    static isElementInViewport(el, offsetTop) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) * offsetTop &&
            rect.left >= 0 &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    /**
     * Logs event and notifies listeners.
     * @param {Object} dmEvent DM Event data object.
     */
    logAndEmit(dmEvent) {
        // add to event log
        this.eventLog.push(dmEvent);

        // emit event to listener(s)
        this.emit('onTrackEvent', dmEvent);

        // set current timestamp as last event timestamp
        this.lastEventTimeStamp = dmEvent.timestamp;

        // reset current Event
        this.currentEvent = {};
    }

    /**
     * Updates current DM elements and components.
     * @param {boolean} [doInit=true] Boolean to run init after update (default) or not.
     * @returns void
     */
    update(doInit = true) {
        this.components = [];
        Array.from(document.querySelectorAll(this.config.componentSelector)).forEach((node, i) => {
            node.dataset.dmComponentIndex = i;
            this.components.push(node);
        });
        this.elements = [];
        Array.from(document.querySelectorAll(this.config.elementSelectors)).forEach((node, i) => {
            node.dataset.dmElementIndex = i;
            this.elements.push(node);
        });
        if (doInit) {
            this.init();
        }
    }
}

export default ValTagManager;
