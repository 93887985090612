
/**
 * Generic module to pass value from one form field to the other.
 * Set following data attributes on input fields:
 *
 * form-value-pass-input="<ID>"
 * form-value-pass-output="<ID>"
 *
 * and value of input will be mirrored from input to output field.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Martijn Wennekes <martijn.wennekes@valtech.com>
 */

const PassFormValue = {
    init($el) {
        const inputfield = $el;
        const id = inputfield.getAttribute('form-value-pass-input');
        const outputField = document.querySelector(`[form-value-pass-output=${id}]`);

        if (inputfield) {
            inputfield.addEventListener('blur', () => {
                const val = inputfield.value;
                if (outputField) {
                    outputField.value = val;
                }
            });
        }
    },
};

export default PassFormValue;