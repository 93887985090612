/**
 * EventEmitter
 * Base class to let extends emit events to registered listeners.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class EventEmitter {

    constructor() {
        this.listeners = new Map();
    }

    /**
     * Adds a listener to events being emitted.
     * @param {string} label Event label to listen to.
     * @param {function} callback Callback to register.
     */
    addListener(label, callback) {
        if (!this.listeners.has(label)) {
            this.listeners.set(label, []);
        }
        this.listeners.get(label).push(callback);
    }

    /**
     * Removes a listener to events being emitted.
     * @param {string} label Event label to listen to.
     * @param {function} callback Callback to register.
     */
    removeListener(label, callback) {
        let listeners = this.listeners.get(label),
            index;

        if (listeners && listeners.length) {
            index = listeners.reduce((i, listener, index) => {
                return (typeof listener === 'function' && listener === callback) ?
                    i = index :
                    i;
            }, -1);

            if (index > -1) {
                listeners.splice(index, 1);
                this.listeners.set(label, listeners);
                return true;
            }
        }
        return false;
    }

    /**
     * Emits events to listeners.
     * @param {string} label Name of event to emit.
     * @param {*} args Arguments.
     */
    emit(label, ...args) {
        let listeners = this.listeners.get(label);

        if (listeners && listeners.length) {
            listeners.forEach((listener) => {
                listener(...args);
            });
            return true;
        }
        return false;
    }
}

export default EventEmitter;