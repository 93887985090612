import 'bootstrap/js/dist/modal.js';
import ResponsiveBackground from 'ResponsiveBackground';

/**
 * Modal Base Class for Bootstrap Modals.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class Modal {
    constructor(element) {
        this.$element = $(element);

        this.$modalElement = null;
        this.data = null;
        this.html = null;
    }

    init() {
        // get DOM element to place in the modal
        this.$modalElement = $('#' + this.$element.attr('data-modal-element')).clone();
        // this.$img = this.$modalElement.find('img.bg');

        // add events if modal is defined
        if (this.$modalElement.length > 0) {
            this.html = this.$modalElement.html();

            this.$element.on('click', () => {
                this.$modalElement.modal('show');
            });

            this.$modalElement.on('show.bs.modal', (e) => {
                this.updateModal(e);
            });
        }
    }

    updateModal(e) {
        let $modal = $(e.currentTarget);
        let html = '' + this.html;

        // get attribute data
        this.data = this.getAttributeData();

        for (const replacement of this.data) {
            html = html.replace('##' + replacement.name.toUpperCase() + '##', replacement.value);
        }

        $modal.html(html);

        $modal.find('.js-responsive-background').each((index, el) => {
            new ResponsiveBackground($(el)).init();
        });
    }

    /**
     * Fetches attribute data from element.
     * @returns {array} Array of name/value pairs.
     */
    getAttributeData() {
        let attrs = [];

        $(this.$element[0].attributes).each((i, attr) => {
            if (attr.nodeName.indexOf('data-modal-') > -1) {
                attrs.push({
                    name: attr.nodeName.substr('data-modal-'.length),
                    value: unescape(attr.value),
                });
            }
        });
        return attrs;
    }
}

export default Modal;
