import 'jquery-match-height';
import lodash from 'lodash';

/**
 * Wrapper for jQuery plugin js-match-height functionality.
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class EqualHeights {

    constructor() {
        $(window).on('ready.matchheight', this.matchHeight());
    }

    init() {
        $(window).off('ready.matchheight').on('ready.matchheight', this.matchHeight());
        $(window).on('resize.matchheight', lodash.debounce(() => { this.matchHeight(); }, 100));
    }

    /**
     * Equalise elements heights to match each other.
     */
    matchHeight() {
        if (viewport.is('>=md')) {
            // match heights on tablet and up
            $('.js-match-height').matchHeight();
            $('.js-match-minheight').matchHeight({ property: 'min-height' });
        } else {
            // remove on mobile
            $('.js-match-height').matchHeight({ remove: true });
            $('.js-match-minheight').matchHeight({ remove: true });
        }
    }
}

export default new EqualHeights;