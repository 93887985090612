import 'bootstrap/js/dist/collapse.js';

/**
 * Collapse/Expand elements using bootstrap collapse
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class Collapse {
    /**
     * constructor
     * @param {jQueryElement} $element jQueryDomElement which is upgraded with this functionality.
     */
    constructor($element) {
        this.$element = $($element);
        this.$trigger = this.$element.find('.js-collapse-trigger');
        this.collapsible = this.$element.find('.collapse');
    }

    /**
     * Initialize.
     */
    init() {
        this.collapsible.on('show.bs.collapse', this.onShow.bind(this));
        this.collapsible.on('hidden.bs.collapse', this.onCollapsed.bind(this));

        // trigger collapse/expand event
        this.$trigger.on('click', this.toggleCollapse.bind(this));
    }

    /**
     * Show handler.
     */
    onShow() {
        this.$element.addClass('is-active');
    }

    /**
     * Collapse handler.
     */
    onCollapsed() {
        this.$element.removeClass('is-active');
    }

    /**
     * Collapse/expand toggler.
     * @param {event} e Event.
     */
    toggleCollapse(e) {
        e.preventDefault();

        if (!this.$element.hasClass('is-open')) {
            this.$element.addClass('is-open');
            this.$trigger.addClass('is-open');
            this.collapsible.collapse('show');
        } else {
            this.$element.removeClass('is-open');
            this.$trigger.removeClass('is-open');
            this.collapsible.collapse('hide');
        }
    }
}

export default Collapse;
