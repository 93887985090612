/**
 * @module project/Common
 */
import UnfoldingGrid from 'UnfoldingGrid';
import ResponsiveBackground from 'ResponsiveBackground';
import objectFitImages from 'object-fit-images';
import EqualHeights from 'EqualHeights';
import Modal from 'Modal';
import MasonryBase from 'MasonryBase';
import Collapse from 'Collapse';
import ToggleClass from 'ToggleClass';
import DafTagManager from 'DafTagManager';
import 'bootstrap/js/dist/dropdown.js';
import 'bootstrap/js/dist/collapse.js';
import 'bootstrap/js/dist/modal.js';
import 'jquery-match-height';
import 'jquery.scrollto';
import DealerCountrySelect from './lib/DealerCountrySelect.js';
import PassFormValue from './lib/PassFormValue.js';

// reset the mobile menu (remove all added classnames)
//
function resetMobileMenu() {
    const $mobileNav = $('.mobile-navigation');
    const $mobileSwipeNav = $('.mobile-navigation__swipenav');

    $mobileNav.find('.is-open').removeClass('is-open');
    $mobileNav.find('.sub-is-open').removeClass('sub-is-open');
    $mobileNav.find('.subsub-is-open').removeClass('subsub-is-open');
    $mobileSwipeNav.removeClass('swipenav--lvl2');
    $mobileSwipeNav.removeClass('swipenav--lvl3');
    $mobileSwipeNav.find('li:not(.list-inline-item--first)').remove();
    $mobileSwipeNav.find('ul').css('width', '100%');
}

function initComparisonTables() {
    const comparisonTables = $('.comparison-table');

    if (comparisonTables.length > 0) {
        for (let x = 0; x < comparisonTables.length; x++) {
            const rows = $(comparisonTables[x]).find('tr');
            for (let x = 0; x < rows.length; x++) {
                var $cells = $(rows[x]).find('.cell');
                $cells.matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false,
                });
            }
        }
    }
}

// resize the swipe navigation element
//
function resizeSwipeNav() {
    let totalListWidth = 0;

    // Check all LI items in swipenav and calculate total (except active one)
    $('.mobile-navigation__swipenav ul li:not(.list-inline-item.is-active)').each(function () {
        totalListWidth += $(this).outerWidth();
    });

    $('.mobile-navigation__swipenav ul').css('width', 'calc(100% + ' + totalListWidth + 'px)');
}

// check height of header and position the mobile menu
function checkHeaderHeight() {
    const $header = $('header');
    const $mobileNav = $('.mobile-navigation');
    if ($header.length > 0 && $mobileNav.length > 0) {
        $mobileNav.css('top', $header.outerHeight());
    }
}

// mobile slide menu
//
function mobileMenu() {
    const $mobileNav = $('.mobile-navigation');
    const $mobileNavList = $('.mobile-navigation__list');
    const $mobileSwipeNav = $('.mobile-navigation__swipenav');

    // check height of header
    checkHeaderHeight();

    // make back go back
    $('.mobile-navigation .parent .back')
        .off('click')
        .on('click', function (e) {
            e.stopPropagation();

            let $el = $(e.currentTarget);

            if ($el.parent().hasClass('submenu--level3')) {
                $mobileNavList.removeClass('subsub-is-open');
                $mobileSwipeNav.removeClass('swipenav--lvl3');
            } else {
                $mobileNavList.removeClass('sub-is-open');
                $mobileSwipeNav.removeClass('swipenav--lvl2');
            }

            $el.parent().parent().removeClass('is-open');

            // remove last list when going up the tree
            $mobileSwipeNav.find('li').last().remove();
            $mobileSwipeNav.find('li').last().addClass('is-active');

            $('.mobile-navigation__swipenav').scrollTo('.list-inline-item.is-active', 200, {
                easing: 'linear',
                offset: { left: -30 },
                axis: 'x',
            });
        });

    //
    $('.mobile-navigation__swipenav').on('click', '.inner', function (e) {
        let $el = $(e.currentTarget);
        let $elTarget = $(e.target);
        let $data = $el.attr('data-menulevel');

        if ($elTarget.parent().hasClass('is-active')) {
            e.stopPropagation();
            //e.preventDefault();
        } else {
            e.stopPropagation();
            e.preventDefault();

            if ($data === 'level0') {
                $mobileNavList.removeClass('sub-is-open subsub-is-open');
                $('.mobile-navigation__list .submenu--level2').parent('.parent').removeClass('is-open');
                $mobileSwipeNav.removeClass('swipenav--lvl3');

                $('.mobile-navigation__swipenav').scrollTo('.list-inline-item.is-active', 200, {
                    easing: 'linear',
                    offset: { left: -30 },
                    axis: 'x',
                });

                $mobileSwipeNav.find('li').last().remove();
                $mobileSwipeNav.find('ul').removeClass('swipenav--lvl2 is-open');
                $('.mobile-navigation__list .submenu--level2').find('.is-open').removeClass('is-open');
                $('.mobile-navigation__swipenav ul').css('width', '100%');

                //resetMobileMenu();
            } else if ($data === 'level1') {
                $mobileNavList.removeClass('subsub-is-open');
                $mobileSwipeNav.removeClass('swipenav--lvl3');

                $('.mobile-navigation__list .submenu--level3').parent('.parent').removeClass('is-open');

                $('.mobile-navigation__swipenav').scrollTo('.list-inline-item.is-active', 200, {
                    easing: 'linear',
                    offset: { left: -30 },
                    axis: 'x',
                });
            }

            if ($mobileSwipeNav.find('li').length > 1) {
                $mobileSwipeNav.find('li').last().remove();
            }

            $mobileSwipeNav.find('li').last().addClass('is-active');
            resizeSwipeNav();
        }
    });

    // copy clicked parent link
    $('.mobile-navigation .parent')
        .off('click')
        .on('click', function (e) {
            let $el = $(e.currentTarget);
            let $elTarget = $(e.target);
            let $txt = $elTarget.text();
            let $href = $elTarget.attr('href');
            let $data = $elTarget.parents('ul').attr('data-menulevel');
            let $mobileSwipeNavList = $mobileSwipeNav.find('ul');
            let $caret = `<span class='icon icon--chevron-left'>
                                        <svg role='img' title="icon: chevron left">
                                            <use href='/assets/icons/common/ui.svg#chevron-left' />
                                        </svg>
                                    </span>`;

            // If clicked item has submenu prevent default action, else stop bubbling up the event chain
            if ($elTarget.parent().hasClass('parent')) {
                e.stopPropagation();
                e.preventDefault();

                // remove active class from added LI item
                $mobileSwipeNavList.find('li').removeClass('is-active');

                // Create new LI in swipenav
                $mobileSwipeNavList.append(
                    $('<li/>', { class: 'list-inline-item is-active' }).append(
                        $('<a/>', { class: 'inner', text: $txt, href: $href, 'data-menulevel': $data }).prepend(
                            $($caret)
                        )
                    )
                );
            }

            // auto scroll to clicked item
            $('.mobile-navigation__swipenav').scrollTo('.list-inline-item.is-active', 200, {
                easing: 'linear',
                offset: { left: -30 },
                axis: 'x',
            });

            // addclass to .parent LI item
            if (!$el.hasClass('is-open')) {
                $el.addClass('is-open');

                if ($mobileNavList.hasClass('sub-is-open')) {
                    $mobileNavList.addClass('subsub-is-open');
                    $mobileSwipeNav.addClass('swipenav--lvl3 is-open');
                }
                $mobileNavList.addClass('sub-is-open');
                $mobileSwipeNav.addClass('swipenav--lvl2 is-open');
            }

            resizeSwipeNav();
        });

    // link the whole li element
    $mobileNav.on('click', 'li:not(.parent)', function (e) {
        e.stopPropagation();
        let a = $(this).find('> a').attr('href');
        if (a !== undefined) {
            window.location.href = a;
        }
    });
}

// on ready
$(window).ready(() => {
    // init ValTagManager;

    window.digitalData = new DafTagManager();

    // init match height
    EqualHeights.init();

    // form value pass
    $('[form-value-pass-input]').each((index, el) => {
        PassFormValue.init(el);
    });

    // responsive backgrounds
    $('.js-responsive-background:not(.modal .js-responsive-background)').each((index, el) => {
        new ResponsiveBackground($(el)).init();
    });

    // object-fit polyfill
    $('.js-object-fit').each((_, el) => {
        objectFitImages(el);
    });

    // modal
    $('.js-modal').each((index, el) => {
        new Modal($(el)).init();
    });

    // Masonry
    $('.js-masonry-list:not(.archive--news.js-masonry-list)').each((index, el) => {
        new MasonryBase($(el)).init();
    });

    // unfolding grids
    $('.js-unfolding-grid').each((index, el) => {
        new UnfoldingGrid($(el)).init();
    });

    // collapse/expand
    $('.js-collapse').each((index, el) => {
        new Collapse($(el)).init();
    });

    // toggleclass
    $('.js-toggleclass').each((index, el) => {
        new ToggleClass($(el)).init();
    });

    $('.js-dealer-country-select').each((index, el) => {
        new DealerCountrySelect($(el)).init();
    });

    // submenu toggle
    const $subnavCollapsed = $('.sub-navigation--collapse');
    if ($subnavCollapsed.length > 0) {
        const $collapseTrigger = $subnavCollapsed.find('.js-toggle-submenu');
        const $collapse = $subnavCollapsed.find('#submenutoggle');
        $collapse.collapse('hide');
        $collapseTrigger.on('click', () => {
            const $icon = $collapseTrigger.find('.icon--submenu');
            if ($icon.hasClass('is-open')) {
                $icon.removeClass('is-open');
                $collapse.collapse('hide');
            } else {
                $icon.addClass('is-open');
                $collapse.collapse('show');
            }
        });
    }

    // toggle mobilemenu
    $('.js-toggle-mobilemenu').on('click', (e) => {
        const $el = $(e.currentTarget);

        if ($el.find('.icon--submenu').hasClass('is-open')) {
            $el.find('.icon--submenu').removeClass('is-open');
            $(document.body).removeClass('lock-scroll');
            $el.parents('.header').find('.mobile-navigation').removeClass('is-open');
            $el.parents('.header').find('.mobile-navigation .mobile-navigation__menu').removeClass('is-open');

            resetMobileMenu();
        } else {
            $el.find('.icon--submenu').addClass('is-open');
            $(document.body).addClass('lock-scroll');
            $el.parents('.header').find('.mobile-navigation').addClass('is-open');
            $el.parents('.header').find('.mobile-navigation .mobile-navigation__menu').addClass('is-open');
        }
    });

    mobileMenu();
    initComparisonTables();
});
