/**
 * Generic Class toggle function
 *
 * @memberOf module:project/Common
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class ToggleClass {

    /**
     * constructor
     * @param {jQueryElement} $element jQueryDomElement which is upgraded with this functionality.
     */
    constructor($element) {
        this.$element = $($element);
        this.$trigger = this.$element.find('.js-toggleclass-trigger');
        this.$triggerTarget = this.$trigger.attr('data-toggleclass-target');
        this.$triggerPreventClick = this.$trigger.attr('data-toggleclass-preventclick');
        // TODO: get multiple targets from data attribute and toggle these selectors
        // this.$targets = this.$triggerTarget.split(' ');
        // this.$triggerTargetElement = $(this.$targets);
        this.$triggerTargetElement = $(this.$triggerTarget);
    }

    /**
     * Initialize.
     */
    init() {
        // trigger toggle class
        this.$trigger.on('click', (e) => {
            this.toggleClass(e);
        });
    }

    /**
     * Collapse/expand toggler
     * Give the trigger element a data-attribute. This element is toggled with a class.
     * Example:
     * <button class="js-toggleclass-trigger" data-toggleclass-target=".card__image">
     */
    toggleClass(e) {
        this.preventClick(e);

        if (!this.$triggerTargetElement.hasClass('is-active')) {
            this.$trigger.addClass('is-active');
            this.$triggerTargetElement.addClass('is-active');
            // TODO: loop through array and add class to each target
            // this.$triggerTargetElement.each(function () {
            //     $(this).addClass('is-active');
            // });
        } else {
            this.$trigger.removeClass('is-active');
            this.$triggerTargetElement.removeClass('is-active');
        }
    }

    /**
     * prevent click event if data attribute is defined
     * Example:
     * <button class="js-toggleclass-trigger" data-toggleclass-target=".card__image" data-toggleclass-preventclick>
     */
    preventClick(e) {
        if (typeof this.$triggerPreventClick !== 'undefined') {
            e.preventDefault();
        }
    }

}

export default ToggleClass;