import ValTagManager from './ValTagManager.js'; // this previously was absolute path 'ValTagManager', but we need relative path to be able to import this DafTagManager script in mock-files on DAF-Azure

/**
 * DAF extend to the ValTagManager to measure extra elements or components.
 *
 * @memberOf module:project/Common
 * @requires module:project/Common.ValTagManager
 * @version 2.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class DafTagManager extends ValTagManager {
    /**
     * Collects all components and elements to send to VTM Helper and adds
     * corresponding event listeners to the collected nodes.
     */
    constructor() {
        // default config
        const defaults = {
            // Note: defining elementSelectors here does not extend the
            // elementSelectors in ValTagManager, it REPLACES them.
            // not clear to me why they previous devs have chosen not to keep using
            // the generic a, button, input etc but added a class specifier to it?
            elementSelectors: `.meta-navigation a,
                .meta-navigation button,
                .main-navigation a,
                .main-navigation button,
                .mobile-navigation a,
                .mobile-navigation button,
                main a,
                main button,
                main input,
                main select,
                main textarea,
                .dealerlist li,
                .carousel-indicators li,
                .footer a,
                [data-configurator-action]`,
        };
        super(defaults);
    }

    /**
     * Finds DM elements on component level and adds event handlers.
     * @returns void
     */
    init() {
        super.init();
    }

    /**
     * Adds event handling for DAF only DM elements.
     * because this class extends ValTagManager, calls in VTM made to
     * this.handleEvent will point to this function here.
     * at the end of this function, we call super.handleEvent.
     * @param {Event} e Original event.
     */
    handleEvent(e) {
        const node = e.currentTarget;
        let defEvent = this.createDMEvent(e);

        if (e.type === 'click' && node.nodeName === 'LI') {
            if (node.closest('.dealerlist')) {
                // dealer locator dealer list click
                this.currentEvent = {
                    ...defEvent,
                    eventName: 'vtm.click',
                    eventAction: 'link-details-open',
                    elementValue: e.currentTarget.dataset.code,
                    elementText: e.currentTarget.querySelector('.dealerlist__dealername').innerText,
                };
            } else {
                this.currentEvent = {
                    ...defEvent,
                    eventName: 'vtm.click',
                    eventAction: 'button',
                    elementValue: e.currentTarget.dataset.dmElementValue,
                };
            }
            this.logAndEmit(this.currentEvent);
        } else if (e.type === 'click' && node.nodeName === 'A' && node.classList.contains('block-link')) {
            this.currentEvent = {
                ...defEvent,
                eventName: 'vtm.click',
                eventAction: 'truck-configurator-selection',
                elementValue: e.currentTarget.dataset.id,
                elementText: e.currentTarget.querySelector('.block-link-title').innerText,
            };
            this.logAndEmit(this.currentEvent);
        } else if (
            e.type === 'click' &&
            node.nodeName === 'A' &&
            (node.protocol === 'tel:' || node.protocol === 'mailto:') &&
            node.closest('.flyout')
        ) {
            // dealer locator details email and telephone links

            // let super handle event, but do not log and emit (yet).
            super.handleEvent(e, false);

            // overwrite elementText of currentEvent
            this.currentEvent.elementText = node.closest('.flyout').querySelector('.organization-name').innerText;

            // now, log and emit
            this.logAndEmit(this.currentEvent);
        } else if (
            e.type === 'click' &&
            node.nodeName === 'A' &&
            (node.protocol !== 'tel:' || node.protocol !== 'mailto:') &&
            node.closest('.flyout')
        ) {
            // dealer locator details button
            super.handleEvent(e, false);
            this.currentEvent.elementValue = node.closest('.flyout').querySelector('.organization-name').innerText;
            this.logAndEmit(this.currentEvent);
        } else if (e.type === 'submit' && node.hasAttribute('data-vehicle-upgrade')) {
            this.currentEvent = {
                ...defEvent,
                eventName: 'vtm.vehicle-upgrade',
                eventAction: defEvent.elementDataset.dmEventAction,
                elementValue: defEvent.elementDataset.dmElementValue,
            };
            this.logAndEmit(this.currentEvent);
        }

        if (e.type === 'click' && node.hasAttribute('data-configurator-action')) {
            const action = node.dataset.configuratorAction;

            switch (action) {
                case 'step': {
                    // setTimeout is used to wait until default event is prevented
                    // slick carousel does this when the user 'swipes' the clicked item
                    // we only want to send an event when the event is not prevented
                    setTimeout(() => {
                        if (!e.defaultPrevented) {
                            const flow = this.getFlowValue() || node.dataset.flow;
                            const step = node.dataset.step;
                            if (!flow && !step) return;

                            const title = node.getAttribute('title');
                            this.currentEvent = this.getEventFromTCStep(flow, step, title);
                            this.currentEvent.eventName = 'vtm.configurator';
                            this.logAndEmit(this.currentEvent);
                        }
                        return;
                    }, 500);
                    break;
                }
                case 'filter': {
                    const IdActionTransform = {
                        'BUSIN SEGMNT': 'filter bussiness segment',
                        'RTS/TSI SRS': 'filter series',
                    };
                    const filterAnswer = node.querySelector('.answer');
                    const action = IdActionTransform[node.dataset.id];

                    // filter selection
                    if (filterAnswer) {
                        this.currentEvent = {
                            action,
                            value: filterAnswer.textContent,
                        };
                    } else {
                        // filter input range
                        if (!e.eventAction || !e.eventValue) return;

                        this.currentEvent = {
                            action: e.eventAction,
                            value: e.eventValue,
                        };
                    }

                    this.currentEvent.flow = 'Results';

                    break;
                }
                case 'result': {
                    const { model } = node.dataset;
                    this.currentEvent = {
                        flow: 'Results',
                        action: 'select from list',
                        value: model,
                    };
                    break;
                }
                case 'summary': {
                    const modelTitle = document.querySelector('.detail-truck-title');
                    const { action } = node.dataset;
                    this.currentEvent = {
                        flow: 'Summary',
                        action,
                        value: modelTitle && modelTitle.textContent,
                    };
                    break;
                }
                case 'configure': {
                    const modelTitle = document.querySelector('.detail-truck-title');
                    const { optionCategory, option, optionValue } = node.dataset;
                    this.currentEvent = {
                        flow: 'Configure',
                        action: modelTitle && modelTitle.textContent,
                        value: `set ${optionCategory} to ${option} - ${optionValue}`,
                    };
                    break;
                }
                case 'menu': {
                    if (!node) return;
                    const { action } = node.dataset;
                    const modelTitle = document.querySelector('.detail-truck-title');

                    this.currentEvent = {
                        eventName: 'vtm.configurator',
                        flow: 'Menu',
                        action,
                        value: action === 'start over' || action === 'open' ? '' : modelTitle && modelTitle.textContent,
                    };
                }
            }

            this.currentEvent.eventName = 'vtm.configurator';
            this.logAndEmit(this.currentEvent);
        } else {
            super.handleEvent(e);
        }
    }

    getEventFromTCStep(flow, step, value) {
        const ProductFlowSteps = {
            1: 'select series',
            2: 'select chassis type',
        };

        const ApplicationFlowSteps = {
            1: 'select business segment',
            2: 'select application',
            3: 'select chassis type',
        };

        switch (flow) {
            case 'product': {
                return {
                    flow: 'Discover',
                    action: ProductFlowSteps[step] || 'start',
                    value,
                };
            }
            case 'application': {
                return {
                    flow: 'Assist',
                    action: ApplicationFlowSteps[step] || 'start',
                    value,
                };
            }
            default: {
                return {};
            }
        }
    }

    triggerVirtualPageView({ title, name, page }) {
        const { href } = window.location;
        const path = window.location.pathname + window.location.search;
        const event = {
            eventName: 'vtm.virtualpageview',
            title: `${title} - ${name}`,
            page: `${path}&-${page}`,
            location: `${href}&-${page}`,
        };
        this.logAndEmit(event);
    }

    getFlowValue() {
        const flow = document.getElementById('Flow');
        return flow && flow.value;
    }
}

export default DafTagManager;
